import { Component, OnInit } from '@angular/core';
import { BlobService } from '../blob.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  blobs;

  activeBlob: {};

  animationTrigger() {
    const aboutBlobContainer = document.querySelector('.about-blob__container');
    aboutBlobContainer.classList.add('active');
  }

  constructor(
    private blobService: BlobService
  ) { }

  ngOnInit() {
    this.blobService.getBlobs()
      .subscribe(res => this.blobs = res);
  }
}