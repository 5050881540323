import { InvokeMethodExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { TimelineInfoService } from '../../timeline.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  timelineInfo;
  
  constructor(
    private timelineService: TimelineInfoService
  ) { }

  ngOnInit() {
    this.timelineService.getTimelineInfo()
      .subscribe(res => this.timelineInfo = res);
  }

}
