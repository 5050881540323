import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { HondaComponent } from './honda/honda.component';
import { TdComponent } from './td/td.component';
import { HondaAtvComponent } from './honda-atv/honda-atv.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BlobService } from './blob.service';
import { TimelineInfoService } from './timeline.service';
import { TimelineComponent } from './about/timeline/timeline.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HondaComponent,
    TdComponent,
    HondaAtvComponent,
    NavigationComponent,
    TimelineComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [BlobService, TimelineInfoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
