import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { HondaComponent } from "./honda/honda.component";
import { TdComponent } from "./td/td.component";
import { HondaAtvComponent } from "./honda-atv/honda-atv.component";


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'honda', component: HondaComponent },
  { path: 'td', component: TdComponent },
  { path: 'honda-atv', component: HondaAtvComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
